import { graphql } from 'gatsby';
import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import SanityImage from 'gatsby-plugin-sanity-image';
import styled from 'styled-components';
import SEO from '../components/SEO';

const AboutStyles = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4rem;

  h1 {
    text-align: left;
  }
  .bio-image {
    max-width: 300px;
    height: auto;
    margin: auto;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    .bio-image {
      width: 100%;
    }
  }
`;

export default function AboutPage({ data: { about } }) {
  // const about = data.allSanityAbout.nodes[0];
  return (
    <>
      <SEO title="About" />
      <AboutStyles className="content">
        <div>
          <h1 className="title">{about.title}</h1>
          <BlockContent
            className="block"
            blocks={about._rawBio}
            // serializers={serializers}
            projectId="o6ahhntv"
            dataset="production"
          />
        </div>
        <SanityImage
          {...about.image}
          className="pixelated-load shadow rounded bio-image"
        />
      </AboutStyles>
    </>
  );
}

export const query = graphql`
  query MyQuery {
    about: sanityAbout {
      id
      title
      image {
        ...ImageWithPreview
        alt
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawBio
    }
  }
`;
